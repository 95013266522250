
import { renderData } from "@libraryHelpers/dataComponents.js";
import useIsMobile from "@libraryComposables/useIsMobile";
import { useIsExpired } from "@libraryComposables/useIsExpired";
import CoreBlockFooterDisclosures from "./CoreBlockFooterDisclosures.vue";
import CoreBlockFooterBanner from "./CoreBlockFooterBanner.vue";
import CoreBlockFooterOneTrust from "./CoreBlockFooterOneTrust.vue";
import CoreBlockFooterLogo from "./CoreBlockFooterLogo.vue";
import CoreBlockFooterBackground from "./CoreBlockFooterBackground.vue";
import CoreBlockFooterSocialLinks from "./CoreBlockFooterSocialLinks.vue";
import CoreBlockFooterContactGroup from "./contact/CoreBlockFooterContactGroup.vue";

export default {
	name: "CoreBlockFooter",
	components: {
		CoreBlockFooterDisclosures,
		CoreBlockFooterBanner,
		CoreBlockFooterOneTrust,
		CoreBlockFooterLogo,
		CoreBlockFooterContactGroup,
		CoreBlockFooterSocialLinks,
		CoreBlockFooterBackground,
	},
	props: {
		site: {
			type: Object,
			default: () => {
				return {};
			},
		},
		isBuilder: {
			type: Boolean,
			default: false,
		},
		// the builder preview is set to mobile view
		isBuilderMobile: {
			type: Boolean,
			default: false,
		},
		isEditable: {
			type: Boolean,
			default: false,
		},
	},
	setup(props) {
		const { isMobile } = useIsMobile();
		const { isExpired } = useIsExpired(
			props.site.footer,
			(item) => item?.propertyKey === "image"
		);

		return { isExpired, isMobile };
	},
	data() {
		return {
			clonedSite: null,
		};
	},
	computed: {
		isNYL() {
			return this?.site?.brandId === 3;
		},
		blockClasses() {
			const classList = [];
			if (this.isBuilder) {
				classList.push("footer__pointer_all");
			}
			return classList;
		},
		pageTree() {
			try {
				let pageTree;
				try {
					pageTree = this?.site?.pages.filter(
						(p) =>
							!p.isPrivate &&
							!p.parentPageId &&
							this?.site?.homepageId != p.id &&
							!JSON.parse(p.settings)?.isNavButton
					);
				} catch (err) {
					pageTree =
						this?.site?.pages.filter(
							(p) =>
								!p.isPrivate &&
								!p.parentPageId &&
								this?.site?.homepageId != p.id &&
								!p.settings?.isNavButton
						) || [];
				}
				// This adds the custom nav link button to the footer as another column.
				const navButton = this?.site?.pages?.find((p) => {
					try {
						return JSON.parse(p.settings)?.isNavButton;
					} catch {
						return p.settings?.isNavButton;
					}
				});
				if (navButton) {
					pageTree.push(navButton);
				}

				return pageTree;
			} catch (err) {
				return [];
			}
		},
		footer() {
			return this.site?.footer || {};
		},
		copyright() {
			return renderData(this.footer.copyright, this.clonedSite);
		},
		copyrightText() {
			return renderData(
				this.footer?.copyrightText || "",
				this.clonedSite
			);
		},
		showFooter() {
			return this.footer.showFooter || false;
		},
		showBanner() {
			return this.footer.showBanner || false;
		},
		showCopyright() {
			return this.footer.showCopyright || false;
		},
		isDBA() {
			return !!this.site.user?.ddcUserData?.dba1Nm || false;
		},
		isRegRep() {
			return this.site.user?.ddcUserData?.regRepInd === "Y";
		},
		isGO() {
			return this.site.tags?.includes(1305) ?? false;
		},
		anyMemberIsRegRep() {
			return this.site.teamMembers?.some((m) => m.regRepInd === "Y");
		},
		isCenterForm() {
			return this.footer.component === "center-aligned-form";
		},
		showForm() {
			const formTypes = ["left-aligned-form", "center-aligned-form"];
			return formTypes.includes(this.footer.component);
		},
		showImage() {
			const imageTypes = [
				"large-image-three-contact",
				"small-image-three-contact",
			];
			return imageTypes.includes(this.footer.component);
		},
		showContacts() {
			const contactTypes = [
				"large-image-three-contact",
				"small-image-three-contact",
				"center-logo-two-contact",
				"top-logo-three-contact",
				"left-logo-three-contact",
				"left-logo-stack-contact",
				"center-logo-stack-contact",
			];
			return contactTypes.includes(this.footer.component);
		},
		isLargeImage() {
			return this.footer.component === "large-image-three-contact";
		},
		image() {
			return this.footer.image || {};
		},
		imageClass() {
			if (!this.showImage) {
				return "";
			}
			return this.isLargeImage
				? "footer__image--large"
				: "footer__image--small";
		},
		formSettings() {
			return {
				type: "form",
				formType: "agentCustom",
				variants: {
					buttonAlign: this.isCenterForm
						? "button-center"
						: "button-left",
				},
			};
		},
		formHeadline() {
			return this.footer.formHeadline;
		},
		navItems() {
			return this?.site?.navigation?.links || [];
		},
		isFooterMobile() {
			if (this.isBuilderMobile) {
				return true;
			}

			return this.isMobile;
		},
		isLogoLeft() {
			return (
				(!this.showForm &&
					!this.showImage &&
					!this.isLogoCenter &&
					!this.isLogoTop) ||
				!this.isLogoRight
			);
		},
		isLogoRight() {
			return !this.isCenterForm || this.isLargeImage;
		},
		isLogoCenter() {
			const contactTypes = [
				"center-logo-two-contact",
				"center-logo-stack-contact",
			];
			return contactTypes.includes(this.footer.component);
		},
		isLogoTop() {
			return this.footer.component === "top-logo-three-contact";
		},
	},
	watch: {
		// Connects FINRA banner with copyright and updates data for mustache templates
		showBanner() {
			this.addDataToSite();
		},
	},
	created() {
		this.addDataToSite();
	},
	methods: {
		handleToggle() {
			if (
				this.isBuilder &&
				this.isEditable &&
				!this.$store.state.interface.isLeftRailClosing
			) {
				if (!this.$store.state.interface.activeSidebarKey) {
					this.$store.commit(
						"interface/toggleActiveSidebarKey",
						"footer"
					);
				} else {
					this.$store.commit("interface/setActiveSidebarKey");
				}

				this.$store.commit(
					"interface/setRightRailOpening",
					!this.$store.state.interface.isRightRailOpening
				);
				this.$store.commit("site/clearSelectedBlockIds"); // will clear selected blocks without influencing the active sidebar key
				setTimeout(() => {
					const targetElement = document.querySelector(
						".editor-main-navigation"
					);
					if (targetElement)
						targetElement.scrollIntoView({ behavior: "smooth" });
				}, 500);
			}
		},
		addDataToSite() {
			// here we can add various data points to use in the footer via mustache templates
			// needs to be done in created so that the computed data is available in the mustache templates
			const year = process.client ? new Date().getFullYear() : null;

			// Show 4 line version copyright if FINRA banner is toggled on and some other conditions
			const needsCopyright =
				(this.isRegRep && !this.isDBA) || this.isGO || this.showBanner;
			this.clonedSite = { year, needsCopyright, ...this.site };
		},
	},
};
